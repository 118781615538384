import React from "react"

import "../../styles/global.css"

import { useStaticQuery, graphql } from "gatsby"

export default function About() {
  const data = useStaticQuery(graphql`
    {
      image: allFile(
        filter: {
          relativeDirectory: { eq: "homePage" }
          name: { eq: "about-image" }
          extension: { eq: "svg" }
        }
      ) {
        edges {
          node {
            publicURL
          }
        }
      }
    }
  `)

  var { image } = data
  image = image.edges[0].node
  return (
    <div
      id="about-us"
      className="md:w-3/4 md:justify-between md:items-start md:flex-row-reverse md:mt-16 w-full flex flex-col-reverse justify-center items-center px-2"
    >
      <div className="flex md:w-7/12 md:self-stretch md:items-start md:justify-between w-full justify-center items-center flex-col my-3">
        <div className="2xl:text-5xl xl:text-4xl md:text-2xl text-2xl 2xl:[line-height:70px] xl:[line-height:50px]  font-helvetica font-bold text-text-primary uppercase md:text-left text-center mb-2">
          about us
        </div>
        <div className="w-3/4 2xl:text-xl md:text-sm text-sm md:mb-24 md:text-left font-thin font-helvetica text-text-primary text-center mb-2">
          VSN Shared Services LLP is a full-service accounting and auditing,
          business process offshoring company inter-alia practicing in the area
          of Book Keeping, Audit & Assurance, Tax Advisory and Compliance
          Support, Payroll Processing, Fund Management, VCFO or part-time CFO
          services and Budgeting.
        </div>
        <h1 className="xl:text-lg text-sm button-primary mb-2 font-semibold font-roboto lg:mt-2 btn btn-md rounded-full px-10">
          <a href="/about">learn more</a>
        </h1>
      </div>
      <div className="w-6/12 pt-2 md:self-stretch md:mr-20">
        <img className="h-full" src={image.publicURL} alt="about" />
      </div>
    </div>
  )
}
