import React from "react"

import ServiceCard from "./ServiceCard"

export default function Services() {
  return (
    <div
      id="our-services"
      className="w-full md:my-10 flex flex-col justify-start items-center my-10 overflow-scroll service-scroll"
    >
      <div className="w-3/4 flex justify-center flex-col items-center text-center font-helvetica md:mb-0 my-20">
        <h1 className="2xl:text-5xl xl:text-4xl md:text-2xl text-2xl text-text-primary font-semibold uppercase lg:mb-14">
          We bring the best services to you
        </h1>
        <p className="2xl:text-xl md:text-sm text-sm text-text-primary">
          Ease your compliance burden without having to worry about deadlines as
          we
          <br /> support you with our team of experts
        </p>
      </div>
      <div className="w-full">
        <div className="w-full">
          <div className="flex lg:flex-row lg:flex-wrap lg:justify-around lg:items-center lg:my-10 w-full my-1 flex-col justify-evenly items-center mb-20 px-2">
            <ServiceCard
              title="accounting"
              image="accounting"
              content={
                "VSN is a leading accounting firm that provides robust bookkeeping services to CPAs and small and medium-sized enterprises. We ensure that accounts are maintained as per international reporting standards."
              }
            />
            <ServiceCard
              mid
              title={"financial reporting"}
              image={"financial_reporting"}
              content={
                "Financial reporting helps a business to track its expenses, gross profits, debts, and profitability. We at VSN review, analyse, and flag any potential issues to ensure timely remedial actions are taken."
              }
            />
            <ServiceCard
              title={"tax advisory"}
              image={"tax_advisory"}
              content={
                "Taxation is the key to a nation’s development, we at VSN plan your taxes in a manner that fulfils all the compliances and saves your taxes in the best possible manner."
              }
            />
          </div>
          <div className="flex lg:h-1/2 lg:flex-row lg:flex-wrap lg:justify-around lg:items-center lg:mt-20 w-full my-1 flex-col justify-evenly items-center mb-20 px-2">
            <ServiceCard
              title={"payroll processing"}
              image={"payroll_processing"}
              content={
                "Managing a business’s payroll can be a cumbersome, time-consuming, and costly affair. We at VSN simplify it for you."
              }
            />
            <ServiceCard
              mid
              title={"audit and assurance"}
              image={"audit_and_assurance"}
              content={
                "Audit & Assurance are like insurances cover which saves you from future litigations and red flags all the disparities in advance"
              }
            />
            <ServiceCard
              title={"vcfo"}
              image={"vcfo"}
              content={
                "Virtual CFO is the need in today’s working environment. It gives the required professional management required for a business at fraction of the cost. "
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}
