import React from "react"

export default function Reason({ title, content, file }) {
  return (
    <div className="lg:w-1/2 w-full h-full flex flex-col justify-center items-center lg:my-4 my-6">
      <div className="w-5/6 h-full flex flex-col lg:justify-start justify-center items-center font-helvetica">
        <div className="w-5/6 flex flex-row lg:justify-start justify-center items-center my-1">
          <img
            src={`images/homePage/reasons/${file}.svg`}
            className="w-[108px]"
          />
          <div></div>
        </div>
        <div className="w-5/6 flex flex-row lg:justify-start justify-center items-center my-1">
          <div>
            <h1 className="text-lg font-bold text-text-primary uppercase lg:text-left text-center">
              {title}
            </h1>
          </div>
          <div className="lg:visible hidden"></div>
        </div>
        <div className="lg:w-5/6 w-3/6 flex flex-row lg:justify-between lg:items-start justify-center items-center my-1">
          <div>
            <p className="text-md text-text-primary lg:text-left text-center">
              {content}
            </p>
          </div>
          <div className="lg:visible hidden"></div>
        </div>
      </div>
    </div>
  )
}
