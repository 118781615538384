import React from "react"

import DownArrow from "../svg/DownArrow"

import { Link } from "gatsby"

// export default function VideoSection() {
//   return (
//     <div className="h-screen w-full flex justify-center items-center xl:py-16 ">
//       <div className="md:w-8/12 h-4/6 md:flex md:flex-row xl:p-6 xl:pr-20 bg-[url(../../static/images/homePage/video-bg.png)] rounded-xl bg-no-repeat bg-cover w-10/12 flex flex-col justify-center items-center">
//         <div className="md:w-5/12 md:h-full md:flex md:flex-col md:justify-between md:items-start md:ml-10 md:mr-5 w-full h-2/6 flex flex-col justify-start items-center">
//           <div>
//             <h1 className="mt-10 2xl:text-5xl xl:text-4xl lg:text-2xl md:text-3xl md:text-left text-text-primary uppercase font-bold text-xl text-center">
//               let’s see how <br />
//               your business
//               <br /> will be
//               <br /> benefited <br />
//               from our <br />
//               offshoring services
//             </h1>
//           </div>
// <div className="md:self-start flex flex-row justify-evenly items-center border-2 border-text-primary rounded-lg pl-2 xl:mb-20 md:mb-10 my-2 py-2 px-1">
//   <span className="uppercase text-center md:text-xl text-sm text-text-primary">
//     watch here
//   </span>
//   <span>
//     <img
//       className="md:flex hidden"
//       src="images/homePage/right-arrow.svg"
//       alt="right arrow"
//     />
//   </span>
//   <span className="md:hidden visible">
//     <DownArrow videoSection />
//   </span>
// </div>
//         </div>
// <div className="xl:w-7/12 md:w-full md:h-full flex md:flex-col-reverse md:justify-start md:items-center xl:pb-20 md:pb-10 w-full flex-col justify-end items-center py-2 px-2 h-5/6 bg-red-700">
//   <iframe
//     className="w-full h-3/5 rounded-xl"
//     src="https://www.youtube.com/embed/Llhbb4pllrY?controls=0"
//     title="10 hours and 1 second of pure black screen!"
//     frameborder="0"
//     allow="accelerometer; autoplay; modestbranding; encrypted-media; gyroscope; picture-in-picture"
//     allowfullscreen
//   ></iframe>
// </div>
//       </div>
//     </div>
//   )
// }

export default function VideoSection() {
  return (
    <div className="w-full flex justify-center items-center">
      <div className="lg:w-8/12 w-10/12 flex justify-center items-center bg-[url(../../static/images/homePage/video-bg.png)] bg-cover bg-no-repeat rounded-xl">
        <div className="w-fulllg:p-16 p-5 flex flex-col gap-10 lg:justify-center justify-start items-center">
          <div className="flex-col justify-between items-start">
            <div className="w-full flex lg:justify-start justify-center items-start">
              <h1 className="2xl:text-4xl xl:text-3xl lg:text-4xl md:text-3xl lg:text-left text-text-primary uppercase font-bold text-xl text-center">
                let’s see how <br />
                your business <br /> will be <br /> benefited <br />
                from our <br />
                offshoring services
              </h1>
            </div>
          </div>
          <div className="flex lg:justify-end lg:items-end justify-center items-center">
            <div className="relative w-full h-full flex justify-center items-center">
              <img
                src="images/homePage/thumbnail.jpg"
                alt="video thumbnail"
                className="w-full z-0 rounded-lg"
              />
              <a
                href="https://youtu.be/Llhbb4pllrY"
                target="_blank"
                className="z-10 absolute top-1/2 left-1/2 -translate-x-1/2 -transform-y-1/2 w-fit p-5 lg:py-0 py-4 flex justify-center items-center border-2 border-text-primary rounded-xl cursor-pointer bg-[#9BC1D4]"
              >
                <span className="uppercase text-center md:text-xl text-sm text-text-primary">
                  watch here
                </span>
                <span>
                  <img
                    src="images/homePage/right-arrow.svg"
                    alt="right arrow"
                  />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
