import React, { useRef } from "react"

import { useStaticQuery, graphql } from "gatsby"

import Testimonial from "./Testimonial"

export default function ClientTestimonials() {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allContentfulTestimonial(sort: { fields: createdAt }) {
        nodes {
          author
          designation
          testimonial {
            testimonial
          }
          authorImage {
            gatsbyImageData(width: 100)
          }
          linkedin
        }
      }
    }
  `)
  const { nodes } = data.allContentfulTestimonial
  return (
    <div
      id="testimonials"
      className="lg:my-32 w-full flex flex-col justify-center items-center my-10 pt-5 px-1"
    >
      <div className="lg:w-full w-full flex flex-col justify-center items-center mb-3">
        <h1 className="2xl:text-5xl xl:text-4xl md:text-2xl text-2xl text-text-primary font-semibold uppercase mb-4">
          What our client's say
        </h1>
        <p className="2xl:text-xl md:text-sm text-sm text-text-primary text-center">
          Don’t take our word for it
        </p>
      </div>
      <div className="w-full grid lg:grid-cols-3 gap-10">
        {nodes.map((node, idx) => (
          <Testimonial
            author={node.author}
            content={node.testimonial.testimonial}
            image={node.authorImage}
            designition={node.designation}
            linkedIn={node.linkedin}
            border={idx % 2 !== 0 ? true : false}
          />
        ))}
      </div>
    </div>
  )
}
