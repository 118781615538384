import React from "react"

import { getImage, GatsbyImage } from "gatsby-plugin-image"

export default function Testimonial({
  author,
  designition,
  content,
  image,
  linkedIn,
  border = true,
}) {
  const image_ = getImage(image.gatsbyImageData)
  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="w-full h-full flex flex-col justify-center items-center my-5 px-2 font-helvetica">
        <div className="w-full py-3 flex justify-center flex-col items-center text-center mt-10">
          <div className="my-2">
            <GatsbyImage
              image={image_}
              className="rounded-full aspect-square border-card-border border-2"
            />
          </div>
          <div className="flex gap-1 justify-between items-center">
            <h1 className="uppercase text-lg text-text-primary font-bold my-1">
              {author}
            </h1>
           {linkedIn && <a target="_blank" href={linkedIn}><img className='w-12 cursor-pointer' src="/images/Footer/contact/linkedin.svg"></img></a>}
          </div>
          <div className="w-10/12 flex justify-center items-center">
            <h3 className="text-sm text-text-primary">{designition}</h3>
          </div>
        </div>
        <div
          className={`testimonial-box w-full h-full flex flex-col justify-evenly items-center p-2  rounded-tr-lg rounded-bl-lg rounded-tl-[50px] rounded-br-[50px] pb-10 ${
            !border ? "bg-text-primary" : "border-text-primary border-4"
          }`}
        >
          <div className="w-5/6 flex flex-row justify-between items-center">
            <div>
              <img src="images/homePage/testimonials/quotes.svg" alt="" />
            </div>
            <div></div>
          </div>
          <div className="w-5/6 flex justify-center items-center font-helvetica text-center">
            <p
              className={`text-[18px] text-center ${
                !border ? "text-white" : "text-text-primary"
              }`}
            >
              "{content}"
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
